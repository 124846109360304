<template>
  <div class="hello">
    <h1>欢迎来到老胡棋牌室</h1>
    <p>现在是试运营期间到六月14号结束</p>
    <p>小程序还不能使用，也没有提供售货柜敬请谅解</p>
    <h3>我门提供以下服务以及活动</h3>
    <div class="tip">
      <p>1.试运营期间客户进微信群核销美团团购券码提供电话号码可获赠12元优惠券，正式开业可用；</p>
      <p>2.好评返优惠券无门槛5元优惠券，正式开业可用；</p>
    </div>
    <div class="code">
      <input type="text" v-model="phone" placeholder="手机号">
      <input type="text" v-model="code" placeholder="美团券码">
      <input type="text" v-model="room" placeholder="对应门牌号">
      <button class="codeSub" @click="subCode">提交</button>
    </div>
    <button class="door" @click="openDoor">开门</button>
  </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({
  baseURL: 'https://www.huyouroom.fun', // 服务器URL
  timeout: 1000,                    // 请求超时时间
});
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data () {
    return {
      phone: '',
      code: '',
      room: ''
    }
  },
  methods: {
    subCode() {
      console.log(this.phone)
      instance.get(`/api/code?code=${this.code}&phone=${this.phone}&room=${this.room}`)
      .then(res => {
        console.log(res)
      })
      .catch(error => {
        console.log(error)
      })
    },
    openDoor() {
      instance.get('/api/openDoor?type=open&name=door')
      .then(response => {
        console.log(response)
        setTimeout(() => {
          this.closeDoor();
        }, 1000)
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
    },
    closeDoor () {
      instance.get('/api/openDoor?type=close&name=door')
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 20px 0 0;
}
.codeSub {
  width: 50px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -20px;
  background-color: #42b983;
  color: white;
  font-weight: bold;
  border: unset;
  border-radius: 10px;
}
.code {
  display: flex;
  flex-direction: column;
  position: relative;
}
.code input {
  width: 80%;
  margin-bottom: 5px;
}
p {
  margin: 10px !important;
}
.door {
  width: 100px;
  height: 50px;
  color: white;
  background-color: #df5836;
  border: unset;
  font-size: 20px;
  font-weight: bold;
  border-radius: 10px;
}
.tip {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
